import { useMemo } from "react";
import { LCDClient } from "@terra-money/terra.js";
import { useCurrentChain } from "../contexts/ChainsContext";

const useLCDClient = () => {
  const { lcd, chainID } = useCurrentChain();
  let url = lcd;
  console.log(url, chainID)
  if (url === 'http://localhost:1317') {
    url = 'https://dev-terra-classic-wallet.tezro.com/lcd_proxy'
  }

  const lcdClient = useMemo(
    () => new LCDClient({ URL: url, chainID }),
    [url, chainID]
  );

  return lcdClient;
};

export default useLCDClient;
